import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  topUpCCO,
  withdrawCCO,
  getUsdtBalance,
  getCcoBalance,
  reInvest,
  gameWithdraw,
} from 'services/Web3';
import { Button } from 'templates/Button';
import { ReactModal } from 'templates/ReactModal';
import { Web3Actions } from 'app/Actions';
import Constants from 'app/Constants';
import { players } from 'config';
import { getTransactions } from 'services/Web3';
import translation from 'locales';

const WithdrawModal = ({
  openTopUp,
  value,
  openWithdraw,
  referrer,
  setValue,
  closeModal,
  t,
  address,
  allowed,
  balanceOf,
  setOpenApprove,
  setCCOBalance,
  openReinvest,
  setWithdrawble,
  isGameWithdraw,
  setLoading,
  setLoaded,
}) => {
  const [maximum, setMaximum] = useState(0);
  const promocode = useSelector((state) => state.web3.referer);
  const getWithdrawable = useSelector(
    (state) => state.web3.availableToWithdraw
  );
  const locale = useSelector((state) => state.web3.locale);

  const strings = translation[locale];

  useEffect(() => {
    if (openTopUp) {
      getUsdtBalance(address, setMaximum);
    } else if (openWithdraw && isGameWithdraw) {
      setValue(getWithdrawable);
    } else if (openWithdraw) getCcoBalance(address, setMaximum);
    else if (openReinvest) {
      setValue(getWithdrawable);
    }
  }, [openTopUp, openWithdraw, openReinvest, allowed, balanceOf]);

  const submitForm = () => {
    if (openTopUp) {
      topUpCCO(
        address,
        value,
        promocode ? promocode : Constants.DEFAULT_REF,
        closeModal,
        setCCOBalance,
        setLoading,
        setLoaded
      );
    } else if (openWithdraw && isGameWithdraw) {
      gameWithdraw(
        address,
        value,
        closeModal,
        setCCOBalance,
        setWithdrawble,
        setLoading,
        setLoaded
      );
    } else if (openWithdraw) {
      withdrawCCO(
        address,
        value,
        promocode ? promocode : Constants.DEFAULT_REF,
        closeModal,
        setCCOBalance,
        setLoading,
        setLoaded
      );
    } else if (openReinvest) {
      reInvest(
        address,
        value,
        closeModal,
        setCCOBalance,
        setWithdrawble,
        setLoading,
        setLoaded
      );
    }
  };

  return (
    <ReactModal
      isOpen={openTopUp || openWithdraw || openReinvest}
      onRequestClose={closeModal}
      title="Top up"
    >
      <div
        className="balance-card transaction-card"
        style={{
          backgroundImage: 'url("/assets/images/content/balance-card-bg.svg")',
          width: 420,
        }}
      >
        <div className="balance-card__row">
          <div className="balance-card__title">
            {openTopUp
              ? t(strings['topup.game'])
              : openWithdraw
              ? t(strings['withdraw.game'])
              : openReinvest
              ? t(strings['reinvest'])
              : ''}
          </div>
        </div>
        <div className="balance-card__row" style={{ marginBottom: '1rem' }}>
          <div className="balance-card__col">
            <input
              className="rate__input"
              type="number"
              placeholder={
                openTopUp
                  ? t(strings['topup.game'])
                  : openWithdraw
                  ? t(strings['withdraw.game'])
                  : openReinvest
                  ? t(strings['reinvest'])
                  : ''
              }
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            {/* {(openWithdraw && isGameWithdraw) || openReinvest ? '' : <button
							type='button'
							className='game__head-btn transaction__btn input__btn'
							onClick={() => setValue(parseInt(maximum) / 1_000_000)}
						>
							{t('Максимум')}
						</button>
						} */}
          </div>
        </div>
        <div
          className="balance-card__row"
          style={{
            padding: '0 10px',
            justifyContent: 'space-between',
            marginRight: 0,
          }}
        >
          <div>
            {(openWithdraw && isGameWithdraw) || openReinvest ? null : (
              <span style={{ fontSize: 14 }}>
                {t(strings['money.balance'] + `: ${maximum} ${openTopUp ? 'USDT' : 'PLAY'}`)}
              </span>
            )}
          </div>
        </div>
        <div className="balance-card__row">
          <div className="balance-card__col">
            <Button name="replenish" onClick={submitForm} fullWidth>
              {openTopUp
                ? t(strings['topup.game'])
                : openWithdraw
                ? t(strings['withdraw.game'])
                : openReinvest
                ? t(strings['reinvest'])
                : ''}
            </Button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setCCOBalance: (balance) =>
    Web3Actions.setCCOBalance(
      dispatch({
        type: Constants.SET_CCO_BALANCE,
        data: { ccoBalance: balance },
      })
    ),
  setLoading: (loading) =>
    Web3Actions.setLoading(
      dispatch({
        type: Constants.PAGE_LOADING,
        data: { loading },
      })
    ),
  setLoaded: (loaded) =>
    Web3Actions.setLoaded(
      dispatch({
        type: Constants.PAGE_SUCCESSED,
        data: { loaded },
      })
    ),
});

export default connect(null, mapDispatchToProps)(WithdrawModal);
