import React, { useState } from 'react';
import { compose } from 'redux';
import { NavLink } from 'react-router-dom';
import { withTagDefaultProps } from 'hoc';
import { Logo } from 'templates/Logo';
import { Img } from 'templates/Img';
import { getTransactions } from 'services/Web3';
import translation from 'locales';
import { useSelector } from 'react-redux';
import { Web3Actions } from 'app/Actions';
import { withServiceConsumer } from 'services/Context';
import Constants from 'app/Constants';

const UnAuthHeader = ({ t, setLocale }) => {
  const locale = useSelector((state) => state.web3.locale);
  const strings = translation[locale];

  const [lang, setLang] = useState(false);

  return (
    <>
      <div className="header__col">
        <Logo />
      </div>

      <div className="header__colLast">
        <a
          href="https://millennium.games/x2/support"
          target="_blank"
          className="header__link header__link_simple"
        >
          {t(strings['tech.support'])}
        </a>
        <div className="header__colList">
          <div className="colList languages">
            <button
              className={`${lang && 'border-none'}`}
              onClick={() => setLang(!lang)}
            >
              {locale}
            </button>
            <ul className={`${lang ? 'show' : 'hide'}`}>
              {['en', 'es', 'ru', 'ch', 'co', 'hi'].map((item) => {
                return (
                  <li
                    key={`item-${item}`}
                    onClick={() => {
                      setLocale(item);
                      setLang(false);
                    }}
                  >
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {/* <button className='add-user' type='button'>
					<Img src='/assets/images/icons/add-user.svg' alt='add-user' />
				</button> */}
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    locale: state.web3.locale,
  };
};

const mapDispatchToProps = (dispatch, { service: { getActionStore } }) => ({
  setLocale: (data) =>
    Web3Actions.setLocale(
      dispatch({ type: Constants.SET_LOCALE, data: { locale: data } })
    ),
});

export default compose(
  withServiceConsumer,
  withTagDefaultProps(mapStateToProps, mapDispatchToProps)
)(UnAuthHeader);
