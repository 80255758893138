import React from 'react';

const TextLogo = () => (
    <div className="text-logo">
        <span>x2 </span>
        <span className="color_yellow">play</span>
    </div>
);

export default TextLogo;
