import React from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import { ToastContainer } from "react-toastify"

import { AuthOverlay } from "./widgets/Overlay"
import { PreloaderHoc } from "./hoc"
import { ScrollToTop } from "./templates/ScrollToTop"
import { Wrapper } from "./templates/Content"
import Swap from "pages/Swap"
import Login from "pages/Auth/Login/Index"
import Cabinet from "./pages/Cabinet/Index/index"
import "react-toastify/dist/ReactToastify.css"

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ToastContainer />
      <Wrapper>
        <AuthOverlay>
          <Switch>
            <Route exact path="/" component={Cabinet} />
            <Route path="/login" component={Login} />
            <Route path="/swap" component={Swap} />
          </Switch>
        </AuthOverlay>
      </Wrapper>
    </BrowserRouter>
  )
}

export default PreloaderHoc(App)
