import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  loading: PropTypes.bool.isRequired,
};

const Preloader = ({ loading }) => {
  useEffect(() => {
    if (loading) document.body.classList.add('stop-scrolling');
    else document.body.classList.remove('stop-scrolling');
  }, [loading]);

  return (
    <div className={`preloader ${loading ? 'preloader_show' : ''}`}>
      <div className="preloader__spinner" />
    </div>
  );
};

Preloader.propTypes = propTypes;

export default Preloader;
