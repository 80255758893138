import { Firebase } from "./Firebase"
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  initializeFirestore,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore"

function generateCode() {
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  let text = ""
  for (let i = 0; i < 6; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

const _Firestore = initializeFirestore(Firebase, {
  experimentalForceLongPolling: true,
})

const AllPromocodes = collection(_Firestore, "promocodes")
const SelectUser = (address: string) => doc(_Firestore, "users/" + address)
const SelectReferral = (promocode: string) =>
  doc(_Firestore, "promocodes", promocode)
export const promocode = ""
let _currentUser: User | null = null

const isPromocodeExist = async (promocode: string) => {
  const document = await getDoc(doc(AllPromocodes, promocode))
  console.log("isPromocodeExist ", document.data())

  return Boolean(document.data())
}

const createUser = async (address: string) => {
  const promocode = generateCode()
  const _user = {
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
    address: address,
    mintedAmount: 0,
    referrer: null,
    promocodeDecision: null,
    promocode,
  }

  try {
    await setDoc(SelectUser(address), _user)
    await setDoc(SelectReferral(promocode), { address, referrals: [] })
    _currentUser = _user
  } catch (error) {
    console.error("FirebaseError: CreateUser failed - %o", _user)
    console.error(error)
  }
}

const getUser = async (address: string) => {
  const doc = await getDoc(SelectUser(address))
  const user = await doc.data()

  if (!user)
    throw new ReferenceError(
      "Firebase: User with address [" + address + "] doesn't exists",
    )

  _currentUser = user as User
  return user as User
}

const getAllReferrals = () => getDocs(AllPromocodes)

const getReferrals = async (promocode: string) => {
  const doc = await getDoc(SelectReferral(promocode))
  const referrer = await doc.data()

  return referrer as Referrer
}

const getReferrerAddress = async (promocode: string) => {
  const document = await getDoc(doc(AllPromocodes, promocode))
  const data = await document.data()
  console.log(data)

  if (data) return data.address
  else throw new Error("Can't find referrer address by promocode " + promocode)

  // else return null
}

const updateUser = async (address: string, fields: Partial<User>) => {
  const _user = {
    ...fields,
    updatedAt: Timestamp.now(),
  }
  _currentUser = _user as User
  return setDoc(SelectUser(address), _user, { merge: true })
}

/**
 * Adds promocode to database and returns Promise
 * @param {hash} address Current user wallet address
 * @param {number} promocode Promocode from user input on login page
 * @returns {Promise<void>}
 */

const addPromocode = async (
  walletAddress: string,
  referrer: string,
  promocode: string,
) => {
  try {
    await updateDoc(SelectReferral(referrer), {
      referrals: arrayUnion({ walletAddress, promocode }),
    })
  } catch (error) {}

  return
}

export const Firestore = {
  app: _Firestore.app,
  toJSON: _Firestore.toJSON,
  type: _Firestore.type,
  get currentUser(): Partial<User> | null {
    return _currentUser
  },
  createUser,
  addPromocode,
  isPromocodeExist,
  getReferrals,
  getAllReferrals,
  getReferrerAddress,
  getUser,
  updateUser,
}

export interface User {
  createdAt: Timestamp
  address: string
  mintedAmount: number
  promocodeDecision: boolean | null
  promocode: string
  referrer: string | null
}
export interface Referrer {
  address: string
  referrals: string[]
}
export interface Promocode {
  [address: string]: { address: string; promocode: string }
}
