import React, { useState } from "react"
import { compose } from "redux"
import { NavLink, useHistory } from "react-router-dom"
import { withTagDefaultProps } from "hoc"
import { Logo, TextLogo } from "templates/Logo"
import { Img } from "templates/Img"
import { auth } from "services"
import { useSelector } from "react-redux"
import { Web3Actions } from "app/Actions"
import { withServiceConsumer } from "services/Context"
import Constants from "app/Constants"
import { getTransactions } from "services/Web3"
import translation from "locales"

const AuthHeader = ({ t, setLocale }) => {
  const history = useHistory()
  const logout = () => {
    auth.logout()
    history.push("/login")
  }

  const [lang, setLang] = useState(false)
  const language = useSelector(state => state.web3.locale)
  const locale = useSelector(state => state.web3.locale)

  const strings = translation[locale]

  return (
    <>
      <div className="header__col">
        <Logo />
      </div>
      <div className="header__colLast">
        <a href="https://millennium.games/#games" className="header__link">
          {t(strings["nav.games"])}
        </a>
        <a
          href="https://nft.millennium.games/referrals"
          rel="noreferrer"
          className="header__link">
          {t(strings["reff.program"])}
        </a>
        <button
          rel="noreferrer"
          onClick={() => history.push("/swap")}
          className="header__link">
          {t(strings["nav.swap"])}
        </button>
        <a
          rel="noreferrer"
          href="https://millennium.games/nft"
          className="header__link">
          {t(strings["nav.nft"])}
        </a>

        <a
          href="https://millennium.games/r/guide"
          target="_blank"
          rel="noreferrer"
          className="header__link">
          {t(strings["nav.help"])}
        </a>
        <a
          href="https://millennium.games/support"
          target="_blank"
          rel="noreferrer"
          className="header__link">
          {t(strings["nav.support"])}
        </a>
        <div className="header__colList">
          <div className="colList languages">
            <button
              className={`${lang && "border-none"}`}
              onClick={() => setLang(!lang)}>
              {language}
            </button>
            <ul className={`${lang ? "show" : "hide"}`}>
              {["en", "es", "ru", "ch", "co", "hi"].map(item => {
                return (
                  <li
                    key={`item-${item}`}
                    onClick={() => {
                      setLocale(item)
                      setLang(false)
                    }}>
                    {item}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        {/* <button type="button" className="logout-btn" onClick={logout}>
          <Img src="/assets/images/icons/logout.svg" alt="logout" />
        </button> */}
      </div>
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    locale: state.web3.locale,
  }
}

const mapDispatchToProps = (dispatch, { service: { getActionStore } }) => ({
  setLocale: data =>
    Web3Actions.setLocale(
      dispatch({ type: Constants.SET_LOCALE, data: { locale: data } }),
    ),
})

export default compose(
  withServiceConsumer,
  withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(AuthHeader)
