import React, { useState } from "react"
import { compose, Dispatch } from "redux"
import { LoadPage, withTagDefaultProps } from "hoc"
import { ServiceProvider } from "services/Context"
import Constants from "app/Constants"
import { Web3Actions } from "app/Actions"
import { Container } from "templates/Content"
import { Wrap } from "templates/Content"
import * as service from "../Cabinet/Index/Services"
import WithdrawModal from "../Cabinet/Index/Views/WithdrawModal"
import ApproveModal from "../Cabinet/Index/Views/ApproveModal"
import { BalanceCard } from "templates/Balance"
import { useSelector } from "react-redux"
import translation from "locales"

const Swap = ({ t, setAllowed, setWithdrawable }: any) => {
  const [value, setValue] = useState(0)
  const [openTopUp, setOpenTopUp] = useState(false)
  const [openWithdraw, setOpenWithdraw] = useState(false)
  const [openReinvest, setOpenReinvest] = useState(false)
  const [openApprove, setOpenApprove] = useState(false)
  const [isGameWithdraw, setIsGameWithdraw] = useState(false)

  // redux referer, allowed, balanceOf,

  const referrer = useSelector((state: any) => state.web3.referer)
  const allowed = useSelector((state: any) => state.web3.allowed)
  const balanceOf = useSelector((state: any) => state.web3.balanceOf)
  const balance = useSelector((state: any) => state.web3.ccoBalance)
  const address = useSelector((state: any) => state.web3.address)
  const locale = useSelector((state: any) => state.web3.locale)
  const strings = (translation as any)[locale]

  const closeModal = () => {
    setOpenTopUp(false)
    setOpenWithdraw(false)
    setOpenApprove(false)
    setValue(0)
    setOpenReinvest(false)
    setIsGameWithdraw(false)
  }

  const modalProps = {
    openTopUp,
    value,
    openWithdraw,
    openReinvest,
    isGameWithdraw,
    referrer,
    address,
    t,
    allowed,
    balanceOf,
    openApprove,
    setWithdrawable,
    setOpenApprove,
    setValue,
    closeModal,
    setAllowed: (address: any) => {
      setAllowed(address)
    },
    setWithdrawble: (address: any) => {
      setWithdrawable(address)
    },
  }

  return (
    <ServiceProvider value={service}>
      <Container>
        <Wrap>
          <div className="swap">
            <h1>{t(strings["topup.heading"])}</h1>
            <BalanceCard
              total={balance}
              onClickReplenish={() => setOpenTopUp(true)}
              onClickWithdraw={() => setOpenWithdraw(true)}
            />
            <p className="swap-address">
              {t(strings["topup.address"])} <br /> <span>{address}</span>
            </p>
          </div>
          <WithdrawModal {...modalProps} />
          <ApproveModal {...modalProps} />
        </Wrap>
      </Container>
    </ServiceProvider>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  // onLoad: service.getActionStore('pageLoad')(dispatch),

  setWithdrawable: (balance: any) =>
    Web3Actions.setWithdrawable(
      dispatch({
        type: Constants.SET_WITHDRAWABLE,
        data: { availableToWithdraw: balance },
      }),
    ),
  setAllowed: (balance: any) =>
    Web3Actions.setAllowed(
      dispatch({ type: Constants.SET_ALLOWED, data: { allowed: balance } }),
    ),
})

export default compose(withTagDefaultProps(null, mapDispatchToProps))(Swap)
