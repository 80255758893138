import React, { useState } from "react"
import { collection, doc, setDoc } from "firebase/firestore"
import { compose } from "redux"
import PropTypes from "prop-types"
import { withTagDefaultProps } from "hoc"
import { withServiceConsumer } from "services/Context"
import { Clipboard } from "templates/Button"
import { players } from "config"
import { getTransactions } from "services/Web3"
import translation from "locales"
import { useSelector } from "react-redux"

const propTypes = {
  link: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
}

const ReferalCard = ({ link, status, address, t }) => {
  const user = useSelector(state => state.auth.user)
  // setTimeout(() => {
  //   players
  //     .where('wallet', '==', address)
  //     .get()
  //     .then((querySnapshot) => {
  //       querySnapshot.forEach((doc) => {
  //         if (doc.data().refCode) {
  //           setCode(doc.data().refCode);
  //         }
  //       });
  //     })
  //     .catch((error) => {
  //       console.log('Error getting documents: ', error);
  //     });
  // }, 700);

  const locale = useSelector(state => state.web3.locale)
  const strings = translation[locale]

  return (
    <div id="ref-block" className="referal-card">
      <div className="referal-card__head">
        <div className="referal-card__title">{t(strings["reff.program"])}</div>
        <div className={`referal-card__indicator active`}>
          <span className="status" />
          <span className="status-text">{`${t(strings["status"])}`}</span>
        </div>
      </div>
      <div className="referal-card__label">{t(strings["your.code"])}</div>
      <div className="referal-card__copy">
        <div className="fake-input">{user?.promocode}</div>
        <div className="referal-card__copy-button">
          <Clipboard name="referal-link" value={user?.promocode} />
          <span>{t(strings["copy"])}</span>
        </div>
      </div>
      <div className="referal-card__text">
        {t(strings["reff.program.text1"])}
        <span className="color_yellow"> 1% </span>
        {t(strings["reff.program.text2"])}{" "}
        <span className="color_yellow"> 4% </span>{" "}
        {t(strings["reff.program.text3"])}.
        {/* Когда Ваш реферал приобретает или продаёт токены игры (TCCO),
				<span className='color_yellow'> → </span>
				Он регистрируется по вашей ссылке
				<span className='color_yellow'> → </span>Когда реферал пополняет баланс
				вы гарантировано{' '}
				<span className='color_yellow'>
					получаете 1% от всех сумм его пополнений
				</span> */}
      </div>
    </div>
  )
}

ReferalCard.propTypes = propTypes

export default compose(
  withServiceConsumer,
  withTagDefaultProps(null, null),
)(ReferalCard)
