import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { LoadPage, withTagDefaultProps } from 'hoc'
import { ServiceProvider } from 'services/Context'
import { Firestore, User } from 'services/Firebase/Firestore'
import { Container } from 'templates/Content'
import { MainWrap } from './Views'
import * as service from './Services'
import BlockForm from './Views/BlockForm'
import { useDispatch } from 'react-redux'
import Constants from 'app/Constants'
import { saveAddress } from 'app/Actions/Web3Actions'

enum LOGIN_STATUS {
  IDLE,
  WAITING_PROMOCODE,
  SUCCESS,
  NO_METAMASK,
}

const Login = (props: any) => {
  const [state, setState] = useState<LOGIN_STATUS>(LOGIN_STATUS.IDLE)
  const dispatch = useDispatch()

  const requestMetamask = async (active?: boolean) => {
    const [address] = await window.ethereum.request({
      method: active ? 'eth_requestAccounts' : 'eth_accounts',
    })

    if (address) {
      saveAddress(dispatch)(address)
      setState(LOGIN_STATUS.WAITING_PROMOCODE)
    }

    return address
  }

  async function initApp(active?: boolean) {
    const address = await requestMetamask(active)
    window.ethereum.chainId !== '0x38' &&
      window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x38' }],
      })
    getUser(address)
  }

  useEffect(() => {
    if (window.ethereum) {
      initApp()
    } else {
      setState(LOGIN_STATUS.NO_METAMASK)
    }
  }, [])

  const getUser = (address: string) =>
    Firestore.getUser(address)
      .then((user: User) => {
        dispatch({ type: Constants.SET_USER, data: user })
        user.promocodeDecision === null
          ? setState(LOGIN_STATUS.WAITING_PROMOCODE)
          : setState(LOGIN_STATUS.SUCCESS)
      })
      .catch((e: unknown) => console.error(e))

  return (
    <ServiceProvider value={service}>
      <Container>
        <BlockForm
          changeState={setState}
          state={state}
          requestMetamask={requestMetamask}
        />
      </Container>
    </ServiceProvider>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  onLoad: service.getActionStore('pageLoad')(dispatch),
})

export default (
  compose(withTagDefaultProps(null, mapDispatchToProps), LoadPage) as any
)(Login)
