import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

const Timer = ({ time }) => {
  const locale = useSelector((state) => state.web3.locale);
  const formatDate = moment.unix(time).format('MM/DD/YYYY');
  const calculateTimeLeft = () => {
    let difference = +new Date(`${formatDate}`) - +new Date();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft =
        locale != 'ru'
          ? {
              days: Math.floor(difference / (1000 * 60 * 60 * 24)),
              hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
              minutes: Math.floor((difference / 1000 / 60) % 60),
              seconds: Math.floor((difference / 1000) % 60),
            }
          : {
              дней: Math.floor(difference / (1000 * 60 * 60 * 24)),
              часа: Math.floor((difference / (1000 * 60 * 60)) % 24),
              минут: Math.floor((difference / 1000 / 60) % 60),
              секунд: Math.floor((difference / 1000) % 60),
            };
    } else {
      timeLeft =
        locale != 'ru'
          ? {
              days: 0,
              hours: 0,
              minutes: 0,
              seconds: 0,
            }
          : {
              дней: 0,
              часа: 0,
              минут: 0,
              секунд: 0,
            };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });
  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    timerComponents.push(
      <div className="counter__col">
        <span id="days" className="time">
          {timeLeft[interval]}
        </span>
        <span className="label">{interval}</span>
      </div>
    );
  });

  return (
    <div className="counter-wrap aboveGame">
      <div className="counter">
        {timerComponents.length ? (
          timerComponents
        ) : (
          <span>{locale != 'ru' ? "GAME IS END": "ИГРА ЗАКОНЧИЛАСЬ"}</span>
        )}
      </div>
      <div className="counter-wrap__label">
        <span>{locale != 'ru' ? "UNTIL THE END OF THE GAME": "ДО ЗАВЕРШЕНИЯ ИГРЫ"}</span>
      </div>
    </div>
  );
};
export default Timer;
