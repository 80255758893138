import React from "react"
import { compose } from "redux"
import PropTypes from "prop-types"
import { useHistory, useLocation } from "react-router-dom"
import { withTagDefaultProps } from "hoc"
import { auth } from "services"
import { getTransactions } from "services/Web3"
import translation from "locales"
import { useSelector } from "react-redux"

const propTypes = {
  onClick: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

const Hamburger = ({ t, onClick, show }) => {
  const location = useLocation()
  const history = useHistory()
  const locale = useSelector(state => state.web3.locale)
  const strings = translation[locale]
  const logout = e => {
    e.preventDefault()
    onClick()
    auth.logout()
    history.push("/login")
  }

  if (location.pathname === "/login") return null

  return (
    <div className={`hamburger-wrap ${show ? "active" : ""}`}>
      <button type="button" className="hamburger" onClick={onClick}>
        <span className="hamburger__menu" />
      </button>
      <ul className="menu">
        <li className="menu__item">
          <a href="https://millennium.games/#games" className="menu__link">
            {t(strings["nav.games"])}
          </a>
        </li>
        <li className="menu__item">
          <a
            href="https://nft.millennium.games/referrals"
            rel="noreferrer"
            className="menu__link">
            {t(strings["reff.program"])}
          </a>
        </li>

        <li className="menu__item">
          <button
            rel="noreferrer"
            onClick={() => history.push('/swap')}
            className="menu__link">
            {t(strings["nav.swap"])}
          </button>
        </li>

        <li className="menu__item">
          <a
            rel="noreferrer"
            href="https://millennium.games/nft"
            className="menu__link">
            {t(strings["nav.nft"])}
          </a>
        </li>

        <li className="menu__item">
          <a
            href="https://millennium.games/x2/instruction"
            target="_blank"
            rel="noreferrer"
            className="menu__link">
            {t(strings["nav.help"])}
          </a>
        </li>
        <li className="menu__item">
          <a
            href="https://millennium.games/support"
            target="_blank"
            rel="noreferrer"
            className="menu__link">
            {t(strings["nav.support"])}
          </a>
        </li>
        {/* <li className="menu__item">
          <a className="menu__link" href="/">
            {t(strings["reff.program"])}
          </a>
        </li> */}
        {/* <li className="menu__item">
                    <a className="menu__link" href="/">
                        {t('Профиль')}
                    </a>
                </li> */}
        {/* <li className="menu__item">
          <a className="menu__link" href="/" onClick={logout}>
            {t(strings["log.out"])}
          </a>
        </li> */}
      </ul>
    </div>
  )
}

Hamburger.propTypes = propTypes

export default compose(withTagDefaultProps(null, null))(Hamburger)
