import Constants from "app/Constants"

const initialState = {
  authenticated: false,
  user: null
}

export const authReducer = (state = initialState, action) => {
  const { data, type } = action

  switch (type) {
    case Constants.SET_AUTH:
      return { ...state, authenticated: data }
      break

    case Constants.SET_USER:
      return { ...state, user: data }
      break

    default:
      return state
      break
  }
}
