const Constants = {
    PAGE_LOADED: 'REGISTRATION_PAGE_LOADED',
    PAGE_UNLOADED: 'REGISTRATION_PAGE_UNLOADED',

    FETCH_ITEMS_REQUESTED: 'REGISTRATION_FETCH_ITEMS_REQUESTED',
    FETCH_ITEMS_SUCCESSED: 'REGISTRATION_FETCH_ITEMS_SUCCESSED',
    FETCH_ITEMS_FAILED: 'FETCH_ITEMS_FAILED',

    CHANGE_FORM: 'REGISTRATION_CHANGE_FORM',
};

export default Constants;
