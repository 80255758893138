import React, { useEffect, useState } from "react"
import { compose } from "redux"
import PropTypes from "prop-types"
import { withTagDefaultProps } from "hoc"
import { NavLink, useHistory } from "react-router-dom"
import { withServiceConsumer } from "services/Context"
import { Button } from "templates/Button"
import { AuthSidebar } from "widgets/AuthSidebar"
import { AuthReferal } from "templates/Auth"
import translation from "locales"
import { FormBlock, Input } from "templates/Form"
import { useDispatch, useSelector } from "react-redux"
import { MetamaskLogo } from "templates/MetamaskLogo"
import { connectMetaMask } from "services/Web3"
import { saveAddress } from "app/Actions/Web3Actions"
import { Conditional } from "templates/Conditional"
import { Promocode } from "./Promocode"
import Constants from "app/Constants"
import { Firestore } from "services/Firebase/Firestore"
import { Firebase } from "services/Firebase/Firebase"
import { NoWeb3 } from "pages/__Auth/NoWeb3"

const propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  submitSignInForm: PropTypes.func.isRequired,
}

const BlockForm = ({
  email,
  state,
  password,
  onChange,
  changeState,
  submitSignInForm,
  t,
  requestMetamask,
}) => {
  const dispatch = useDispatch()

  const locale = useSelector(state => state.web3.locale)
  const strings = translation[locale]

  const handleConnectAccount = async () => {
    const address = await requestMetamask(true)
    try {
      await Firestore.getUser(address).then(user => {
        dispatch({ type: Constants.SET_USER, data: user })
      })
      changeState(2)
    } catch (error) {
      await Firestore.createUser(address).then(user => {
        dispatch({ type: Constants.SET_USER, data: user })
      })
      changeState(1)
    }
  }

  return (
    <div className="auth">
      <AuthSidebar />
      <div className="auth__content">
        <div className="auth__body">
          <div className="auth__empty" />
          {
            [
              <div className="auth__idle">
                <h1>Добро пожаловать</h1>
                <h2>Для входа подключите кошелек Metamask</h2>
                <Button
                  name="login"
                  type="submit"
                  disabled={!window.ethereum}
                  icon={<MetamaskLogo />}
                  onClick={() => {
                    handleConnectAccount()
                  }}>
                  {t(strings["sign.in.login"])}
                </Button>
                <a
                  href="https://millennium.games/nft/support"
                  target="_blank"
                  rel="noreferrer">
                  Техническая поддержка
                </a>
                <a
                  href="https://millennium.games/"
                  target="_blank"
                  rel="noreferrer">
                  Все игры
                </a>
              </div>,
              <Promocode onConnect={changeState} />,
              <Success />,
              <div className="auth__idle">
                <h1>Добро пожаловать</h1>
                <h2>Для входа подключите кошелек Metamask</h2>
                <a
                  href="https://metamask.app.link/dapp/x2.millennium.games/auth"
                  className="button button_default">
                  <MetamaskLogo />
                  Продолжить с Metamask
                </a>
                <a
                  href="https://millennium.games/nft/support"
                  target="_blank"
                  rel="noreferrer">
                  Техническая поддержка
                </a>
                <a
                  href="https://millennium.games/"
                  target="_blank"
                  rel="noreferrer">
                  Все игры
                </a>
              </div>,
            ][state]
          }
          <AuthReferal code="HQ032" />
        </div>
      </div>
    </div>
  )
}

BlockForm.propTypes = propTypes

const Success = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)

  useEffect(() => {
    setTimeout(() => {
      dispatch({ type: Constants.SET_AUTH, data: true })
    }, 1500)
  }, [])

  return (
    <h1 style={{ textAlign: "center", fontSize: "36px" }}>Успешный вход</h1>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { getStoreItem } = ownProps.service

  return {
    email: getStoreItem(state, "email", ""),
    password: getStoreItem(state, "password", ""),
    address: state.web3.address,
  }
}

const mapDispatchToProps = (dispatch, { service: { getActionStore } }) => ({
  onChange: getActionStore("onChange")(dispatch),
  submitSignInForm: getActionStore("submitSignInForm")(dispatch),
})

export default compose(
  withServiceConsumer,
  withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(BlockForm)
