import { initializeApp } from "firebase/app"

// ! Firebase Config
export const firebaseConfig = {
  apiKey: "AIzaSyCg1kfUQiE-D3JSiEWSDaVvN76oV13mtJw",
  authDomain: "nft-millenium.firebaseapp.com",
  projectId: "nft-millenium",
  storageBucket: "nft-millenium.appspot.com",
  messagingSenderId: "760145277749",
  appId: "1:760145277749:web:170c8cfb5df46964f3127c",
}

// ! Initialize Firebase singletone
export const Firebase = initializeApp(firebaseConfig)
