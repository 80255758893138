const Constants = {
    PAGE_LOADED: 'FORGOT_PAGE_LOADED',
    PAGE_UNLOADED: 'FORGOT_PAGE_UNLOADED',

    FETCH_ITEMS_REQUESTED: 'FORGOT_FETCH_ITEMS_REQUESTED',
    FETCH_ITEMS_SUCCESSED: 'FORGOT_FETCH_ITEMS_SUCCESSED',
    FETCH_ITEMS_FAILED: 'FETCH_ITEMS_FAILED',

    CHANGE_FORM: 'FORGOT_CHANGE_FORM',
};

export default Constants;
