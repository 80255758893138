import {
  connectWallet,
  connectMetaMask,
  topUpCCO,
  withdrawCCO,
  getUsdtBalance,
  getCcoBalance,
  placeBet,
  getGameInfo,
  getTransactions,
  gameWithdraw,
  reInvest,
  getLastBet,
} from './Web3.js';

export {
  connectWallet,
  connectMetaMask,
  topUpCCO,
  withdrawCCO,
  getUsdtBalance,
  getCcoBalance,
  placeBet,
  getGameInfo,
  getTransactions,
  gameWithdraw,
  getLastBet,
  reInvest,
};
