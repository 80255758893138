import React from 'react';
import { compose } from 'redux';
import { NavLink } from 'react-router-dom';
import { ServiceProvider } from 'services/Context';
import { withTagDefaultProps } from 'hoc';
import { Container } from 'templates/Content';
import { Logo } from 'templates/Logo';
import { Img } from 'templates/Img';
import * as service from './Services';
import { getTransactions } from 'services/Web3';
import translation from 'locales';
import { useSelector } from 'react-redux';

const currentYear = new Date().getFullYear();

const Footer = ({ t }) => {
  const locale = useSelector((state) => state.web3.locale);

  const strings = translation[locale];
  return (
    <ServiceProvider value={service}>
      <footer className="footer">
        <Container>
          <div className="footer__wrap">
            <div className="footer__row">
              <div className="footer__col">
                <Logo />
              </div>
              <div className="footer__col">
                <div className="support">
                  <button type="button">
                    <Img
                      src="/assets/images/icons/telegram.svg"
                      alt="telegram"
                    />
                  </button>

                  <a id='techSupport' href="https://millennium.games/x2/support">
                    <span>{t(strings['tech.support'])}</span>
                  </a>
                </div>
              </div>
              <div className="footer__col">
                <div className="version">{`${t(strings['version'])} 0.02`}</div>
              </div>
            </div>
            <div className="footer__row">
              <div className="footer__col">
                <div className="copyright">{`CCO Online – ${currentYear}`}</div>
                <NavLink to="/" className="politic-link">
                  {t(strings['site.politics'])}
                </NavLink>
              </div>
              <div className="footer__col footer__col_spacer">
                <div className="footer-spacer" />
              </div>
              <div className="footer__col">
                <ul className="footer-nav">
                  <li className="footer-nav__item">
                    <NavLink to="/" className="footer-nav__link">
                      {t(strings['principle.game'])}
                    </NavLink>
                  </li>
                  {/* <li className='footer-nav__item'>
										<NavLink to='/' className='footer-nav__link'>
											{t('Новости')}
										</NavLink>
									</li>
									<li className='footer-nav__item'>
										<NavLink to='/' className='footer-nav__link'>
											{t('Контакты')}
										</NavLink>
									</li> */}
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </footer>
    </ServiceProvider>
  );
};

export default compose(withTagDefaultProps(null, null))(Footer);
