import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { compose } from "redux"
import { withTagDefaultProps } from "hoc"
import { withServiceConsumer } from "services/Context"
import { Wrap } from "templates/Content"
import { ReferalCard } from "templates/Referal"
import BalanceBlock from "./BalanceBlock"
import GamesSlider from "./GamesSlider"
import GameBlock from "./GameBlock"
import ReferalSlider from "./ReferalSlider"
import { Web3Actions } from "app/Actions"
import Constants from "app/Constants"
import { connectWallet } from "services/Web3"
import WithdrawModal from "./WithdrawModal"
import ApproveModal from "./ApproveModal"
import { withRouter } from "react-router"
import { useSelector } from "react-redux"
// import * as service from '../Services';
import { firestore, players } from "config"

import { merge } from "ramda"
import { Firestore } from "services/Firebase/Firestore"

const propTypes = {
  referalLink: PropTypes.string.isRequired,
}

const MainWrap = ({
  referalLink,
  saveAddress,
  setCCOBalance,
  setReferrerAddress,
  setWithdrawable,
  setAllowed,
  setBalanceOf,
  referrer,
  address,
  referer,
  t,
  allowed,
  balanceOf,
  setGameInfo,
  setMyGame,
  setReferalInfo,
  setLastBet,
  setReferer,
}) => {
  const [value, setValue] = useState(0)
  const [openTopUp, setOpenTopUp] = useState(false)
  const [openWithdraw, setOpenWithdraw] = useState(false)
  const [openReinvest, setOpenReinvest] = useState(false)
  const [openApprove, setOpenApprove] = useState(false)
  const [referalStatus, setReferalStatus] = useState(false)
  const [isGameWithdraw, setIsGameWithdraw] = useState(false)
  const connectWalletParams = {
    saveAddress: address => {
      saveAddress(address)
    },
    setCCOBalance: balance => {
      setCCOBalance(balance)
    },
    setReferrerAddress: address => {
      setReferrerAddress(address)
    },
    setWithdrawble: address => {
      setWithdrawable(address)
    },
    setAllowed: address => {
      setAllowed(address)
    },
    setBalanceOf: address => {
      setBalanceOf(address)
    },
    setGameInfo: rate => {
      setGameInfo(rate)
    },
    setMyGame: game => {
      setMyGame(game)
    },
    setReferalInfo: data => {
      setReferalInfo(data)
    },
    setLastBet: data => {
      setLastBet(data)
    },
    setReferer: data => {
      setReferer(data)
    },
  }

  useEffect(() => {
    connectWallet(connectWalletParams)
  }, [])

  // const createWallet = (code) => {
  //   if (address) {
  //     players.add(
  //       {
  //         wallet: address,
  //         refCode: code,
  //       },
  //       { merge: true }
  //     );
  //   }
  // };

  useEffect(() => {
    Firestore.getReferrerAddress(Firestore.currentUser.referrer).then(referrerAddress =>
      setReferer(referrerAddress),
    )
  }, [address])

  const closeModal = () => {
    setOpenTopUp(false)
    setOpenWithdraw(false)
    setOpenApprove(false)
    setValue(0)
    setOpenReinvest(false)
    setIsGameWithdraw(false)
  }

  const modalProps = {
    openTopUp,
    value,
    openWithdraw,
    openReinvest,
    isGameWithdraw,
    referrer,
    address,
    t,
    allowed,
    balanceOf,
    openApprove,
    setWithdrawable,
    setOpenApprove,
    setValue,
    closeModal,
    setAllowed: address => {
      setAllowed(address)
    },
    setWithdrawble: address => {
      setWithdrawable(address)
    },
  }

  const modalParentProps = {
    setOpenTopUp,
    setOpenWithdraw,
    setOpenReinvest,
    setIsGameWithdraw,
  }

  return (
    <Wrap>
      <div className="cabinet">
        <div className="cabinet__row row">
          <div className="cabinet__col col-5">
            <BalanceBlock {...modalParentProps} />
          </div>
          <div className="cabinet__col col-7 cabinet__col_games">
            <GamesSlider />
          </div>
        </div>
        <div className="cabinet__row">
          <div className="cabinet__col col-12">
            <GameBlock {...modalParentProps} />
          </div>
        </div>
        <div className="cabinet__row row">
          <div className="cabinet__col col-7 cabinet__col_referals">
            <ReferalSlider />
          </div>
          <div className="cabinet__col col-5">
            <ReferalCard
              link={referalLink}
              address={address}
              status={referalStatus}
            />
          </div>
        </div>
      </div>
      <WithdrawModal {...modalProps} />
      <ApproveModal {...modalProps} />
    </Wrap>
  )
}

MainWrap.propTypes = propTypes

const mapStateToProps = (state, ownProps) => {
  const { getStoreItem } = ownProps.service
  return {
    referer: state.web3.referer,
    address: state.web3.address,
    allowed: state.web3.allowed,
    balanceOf: state.web3.balanceOf,
    lastBet: state.web3.lastBet,
  }
}

const mapDispatchToProps = (dispatch, { service: { getActionStore } }) => ({
  // onLoad: service.getActionStore('pageLoad')(dispatch),

  saveAddress: address =>
    Web3Actions.saveAddress(
      dispatch({ type: Constants.SET_ADDRESS, data: { address: address } }),
    ),
  setCCOBalance: balance =>
    Web3Actions.setCCOBalance(
      dispatch({
        type: Constants.SET_CCO_BALANCE,
        data: { ccoBalance: balance },
      }),
    ),
  setReferrerAddress: balance =>
    Web3Actions.setReferrerAddress(
      dispatch({
        type: Constants.SET_REFERRER_ADDRESS,
        data: { referrerAddress: balance },
      }),
    ),
  setWithdrawable: balance =>
    Web3Actions.setWithdrawable(
      dispatch({
        type: Constants.SET_WITHDRAWABLE,
        data: { availableToWithdraw: balance },
      }),
    ),
  setAllowed: balance =>
    Web3Actions.setAllowed(
      dispatch({ type: Constants.SET_ALLOWED, data: { allowed: balance } }),
    ),
  setBalanceOf: balance =>
    Web3Actions.setBalanceOf(
      dispatch({
        type: Constants.SET_BALANCE_OF,
        data: { balanceOf: balance },
      }),
    ),
  setGameInfo: data =>
    Web3Actions.setGameInfo(
      dispatch({ type: Constants.SET_GAME_INFO, data: { gameInfo: data } }),
    ),
  setMyGame: data =>
    Web3Actions.setMyGame(
      dispatch({ type: Constants.SET_MY_GAME, data: { myGame: data } }),
    ),
  setReferalInfo: data =>
    Web3Actions.setReferalInfo(
      dispatch({
        type: Constants.SET_REFERAL_INFO,
        data: { referalInfo: data },
      }),
    ),
  setLastBet: lastBet =>
    Web3Actions.setLastBet(
      dispatch({
        type: Constants.SET_LAST_BET,
        data: { lastBet: lastBet },
      }),
    ),
  setReferer: ref =>
    Web3Actions.setReferer(
      dispatch({
        type: Constants.SET_REFERER,
        data: { referer: ref },
      }),
    ),
})

export default compose(
  withRouter,
  withServiceConsumer,
  withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(MainWrap)
