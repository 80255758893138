import Web3 from "web3"
import Constants from "app/Constants"
import { toast } from "react-toastify"
import axios from "axios"

let web3js = ""

let accounts = ""

let ethereum = ""

const connectMetaMask = async ({ saveAddress }) => {
  ethereum = window.ethereum
  if (!ethereum || !ethereum.isMetaMask) {
    alert(`Metamask не установлен!\nВы не можете совершать транзакции`)
    connectWallet({
      saveAddress,
    })
  } else {
    await ethereum.enable()
    web3js = new Web3(window.web3.currentProvider)
    accounts = await web3js.eth.getAccounts()
    saveAddress(accounts[0])
    return accounts[0]
  }
}
const connectWallet = async ({
  saveAddress,
  setCCOBalance,
  setReferrerAddress,
  setWithdrawble,
  setAllowed,
  setBalanceOf,
  setGameInfo,
  setMyGame,
  setReferalInfo,
  setLastBet,
}) => {
  ethereum = window.ethereum
  if (!ethereum || !ethereum.isMetaMask) {
    alert(`Metamask не установлен!\nВы не можете совершать транзакции`)
    connectWallet({
      saveAddress,
      setCCOBalance,
      setReferrerAddress,
      setWithdrawble,
      setAllowed,
      setBalanceOf,
      setGameInfo,
      setMyGame,
      setReferalInfo,
      setLastBet,
    })
  } else {
    await ethereum.enable()
    web3js = new Web3(window.web3.currentProvider)
    accounts = await web3js.eth.getAccounts()
    saveAddress(accounts[0])

    getCCOBalance(accounts[0], setCCOBalance)
    getReferrerAddress(accounts[0], setReferrerAddress)
    getWithdrawable(accounts[0], setWithdrawble)
    getAllowed(accounts[0], setAllowed)
    getBalanceOf(accounts[0], setBalanceOf)
    getGameInfo(setGameInfo)
    getUserGames(accounts[0], setMyGame)
    getUserReferrals(accounts[0], setReferalInfo)
    getLastBet(setLastBet)
  }
}

const getCCOBalance = async (address, setCCOBalance) => {
  let contract = new web3js.eth.Contract(
    Constants.TEST_TOKEN_ABI,
    Constants.TEST_TOKEN,
  )

  try {
    let balance = await contract.methods.balanceOf(address).call()
    setCCOBalance(parseInt(Web3.utils.fromWei(balance)))
  } catch (err) {}
}

const getReferrerAddress = async (address, setReferrerAddress) => {
  let contract = new web3js.eth.Contract(
    Constants.EXCHANGE_ABI,
    Constants.EXCHANGE,
  )

  try {
    let info = await contract.methods.getReferrerInfo(address).call()

    setReferrerAddress(info.referrer)
  } catch (err) {}
}

const getWithdrawable = async (address, setWithdrawble) => {
  let contract = new web3js.eth.Contract(
    Constants.TEST_GAME_ABI,
    Constants.TEST_GAME,
  )

  try {
    let info = await contract.methods.getWithdrawable(address).call()
    setWithdrawble(parseInt(info / 1e18))
  } catch (err) {}
}

const getAllowed = async (address, setAllowed) => {
  let contract = new web3js.eth.Contract(
    Constants.TETHER_TOKEN_ABI,
    Constants.TETHER_TOKEN,
  )

  try {
    let info = await contract.methods
      .allowed(address, Constants.EXCHANGE)
      .call()

    setAllowed(info)
  } catch (err) {}
}

const getBalanceOf = async (address, setBalanceOf) => {
  let contract = new web3js.eth.Contract(
    Constants.TEST_TOKEN_ABI,
    Constants.TEST_TOKEN,
  )

  try {
    let info = await contract.methods.balanceOf(address).call()

    setBalanceOf(info)
  } catch (err) {}
}

const getUsdtBalance = async (address, setMaximum) => {
  let contract = new web3js.eth.Contract(
    Constants.TETHER_TOKEN_ABI,
    Constants.TETHER_TOKEN,
  )

  try {
    let info = await contract.methods.balanceOf(address).call()
    setMaximum(parseInt(Web3.utils.fromWei(info)))
  } catch (err) {}
}

const getCcoBalance = async (address, setMaximum) => {
  let contract = new web3js.eth.Contract(
    Constants.TEST_TOKEN_ABI,
    Constants.TEST_TOKEN,
  )

  try {
    let info = await contract.methods.balanceOf(address).call()

    setMaximum(parseInt(Web3.utils.fromWei(info)))
  } catch (err) {}
}

const topUpCCO = async (
  address,
  amount,
  referer,
  closeModal,
  updatedBal,
  setLoading,
  setLoaded,
) => {
  let contract = new web3js.eth.Contract(
    Constants.EXCHANGE_ABI,
    Constants.EXCHANGE,
  )
  let tetherContract = new web3js.eth.Contract(
    Constants.TETHER_TOKEN_ABI,
    Constants.TETHER_TOKEN,
  )

  try {
    setLoading()
    await tetherContract.methods
      .approve(Constants.EXCHANGE, Web3.utils.toWei(amount))
      .send({ from: address }, (a, s) => {})
      .on("error", err => {
        closeModal()
        toast.error("Упс, что-то пошло не так")
      })
      .on("receipt", async receipt => {
        toast.success("Подтверждено успешно")
        console.log(referer);
        await contract.methods
          .exchangeUsdtToPlay(address, Web3.utils.toWei(amount), referer)
          .send({ from: address })
          .on("receipt", async () => {
            closeModal()
            setLoaded()
            getCCOBalance(address, updatedBal)
            toast.success("Транзакция успешна")
          })
          .on("error", err => {
            closeModal()
            toast.error(err.message)
          })
      })
    console.log("Approve ended")
  } catch (err) {}
}

const withdrawCCO = async (
  address,
  amount,
  referer,
  closeModal,
  updatedBal,
  setLoading,
  setLoaded,
) => {
  let contract = new web3js.eth.Contract(
    Constants.EXCHANGE_ABI,
    Constants.EXCHANGE,
  )
  let testContract = new web3js.eth.Contract(
    Constants.TEST_TOKEN_ABI,
    Constants.TEST_TOKEN,
  )

  try {
    setLoading()
    await testContract.methods
      .approve(Constants.EXCHANGE, Web3.utils.toWei(amount))
      .send({ from: address }, () => {})
      .on("receipt", async () => {
        await contract.methods
          .exchangePlayToUsdt(address, Web3.utils.toWei(amount), referer)
          .send({ from: address })
          .on("receipt", () => {
            closeModal()
            setLoaded()
            getCCOBalance(address, updatedBal)
            toast.success("Транзакция успешна")
          })
          .on("error", err => {
            closeModal()
            toast.error(err.message)
          })
      })
      .on("error", err => {
        closeModal()
        toast.error(err.message)
      })
  } catch (err) {}
}

const gameWithdraw = async (
  address,
  amount,
  closeModal,
  updatedBal,
  setWithdrawble,
  setLoading,
  setLoaded,
) => {
  let contract = new web3js.eth.Contract(
    Constants.TEST_GAME_ABI,
    Constants.TEST_GAME,
  )

  try {
    setLoading()
    await contract.methods
      .withdraw(Web3.utils.toWei(amount))
      .send({ from: address }, () => {})
      .on("receipt", async () => {
        closeModal()
        setLoaded()
        getWithdrawable(address, setWithdrawble)
        getCCOBalance(address, updatedBal)
        toast.success("Транзакция успешна")
      })
      .on("error", err => {
        closeModal()
        toast.error(err.message)
      })
  } catch (err) {}
}

const placeBet = async ({
  sum,
  walAddress,
  setCCOBalance,
  setGameInfo,
  setMyGame,
  setWithdrawble,
  setLastBet,
}) => {
  let contract = new web3js.eth.Contract(
    Constants.TEST_TOKEN_ABI,
    Constants.TEST_TOKEN,
  )
  console.log(sum);
  try {
    await contract.methods
      .transfer(Constants.TEST_GAME, Web3.utils.toWei(sum))
      .send({ from: walAddress })
      .on("transactionHash", hash => {
        console.log("hash ==>>", hash)
      })
      .on("receipt", () => {
        getCCOBalance(walAddress, setCCOBalance)
        getUserGames(walAddress, setMyGame)
        getWithdrawable(walAddress, setWithdrawble)
        getGameInfo(setGameInfo)
        getLastBet(setLastBet)
        toast.success("Ставка успешна")
      })
      .on("error", err => {
        toast.error(err.message)
      })
  } catch (err) {
    console.log("error", err)
  }
}

const getGameInfo = async setGameInfo => {
  let contract = new web3js.eth.Contract(
    Constants.TEST_GAME_ABI,
    Constants.TEST_GAME,
  )

  const gameInfo = await contract.methods.getCurrentGameInfo().call()

  let { _cycle, _index, filled, volume, players, bets, available } = gameInfo
  const items = []

  let width = 30
  for (let obj = 0; obj <= parseInt(_index); obj++) {
    let valueStr = ""
    if (obj < parseInt(_index)) {
      valueStr = await getGameCurrentInfo(contract, _cycle, obj, _index)
    }
    width += 5
    items.push({
      type: obj == parseInt(_index) ? 2 : 1,
      total: `${width}%`,
      filled:
        obj < parseInt(_index)
          ? width + "%"
          : (filled / 1e18 / (volume / 1e18)) * 100 * (width / 100) + "%",
      value:
        obj < parseInt(_index)
          ? valueStr
          : parseInt(filled / 1e18) + "/" + parseInt(volume / 1e18),
    })
  }
  setGameInfo({ filled, volume, _index, players, bets, available, items })
}

const getGameCurrentInfo = async (contract, cycle, from, to) => {
  const gameInfo = await contract.methods.getGamesInfo(cycle, 0, to).call()
  const output =
    gameInfo.filled[from] / 1e18 + "/" + gameInfo.volume[from] / 1e18
  return output
}

const getLastBet = async setLastBet => {
  let contract = new web3js.eth.Contract(
    Constants.TEST_GAME_ABI,
    Constants.TEST_GAME,
  )

  try {
    let unixDate = await contract.methods.getTimer().call()
    setLastBet(unixDate)
  } catch (err) {}
}

const getUserGames = async (address, setMyGame) => {
  const contract = new web3js.eth.Contract(
    Constants.TEST_GAME_ABI,
    Constants.TEST_GAME,
  )
  try {
    const gameInfo = await contract.methods.getCurrentGameInfo().call()
    const { _index } = gameInfo
    const userGames = await contract.methods
      .getUserGames(address, 0, _index + 10)
      .call()
    if (gameInfo && userGames) {
      setMyGame(userGames)
    }
  } catch (err) {}
}

const getUserReferrals = async (address, setReferalInfo) => {
  const gameContract = new web3js.eth.Contract(
    Constants.TEST_GAME_ABI,
    Constants.TEST_GAME,
  )
  const contract = new web3js.eth.Contract(
    Constants.EXCHANGE_ABI,
    Constants.EXCHANGE,
  )
  try {
    const gameInfo = await gameContract.methods.getCurrentGameInfo().call()

    const { _index } = gameInfo

    const referrals = await contract.methods
      .getReferralInfo(address, 0, _index + 10)
      .call()

    setReferalInfo(referrals)
  } catch (err) {}
}

const getTransactions = async (add, setTxnHistory) => {
  const txnHistory = []

  axios({
    method: "GET",
    url: Constants.TXN_HISTORY_URL.replace("{ADDRESS}", add),
    dataType: "json",
  })
    .then(res => {
      for (const ele of res.data.result) {
        if (res.data.result.indexOf(ele) == 50) break
        txnHistory.push({ to: ele.to, from: ele.from })
      }
      setTxnHistory(txnHistory)
    })
    .catch(error => {})
}

const reInvest = async (
  address,
  value,
  closeModal,
  setCCOBalance,
  setWithdrawble,
  setLoading,
  setLoaded,
) => {
  const contract = new web3js.eth.Contract(
    Constants.TEST_GAME_ABI,
    Constants.TEST_GAME,
  )
  try {
    setLoading()
    await contract.methods
      .reinvest(Web3.utils.toWei(value))
      .send({ from: address })
      .on("receipt", () => {
        closeModal()
        setLoaded()
        getCCOBalance(address, setCCOBalance)
        getWithdrawable(address, setWithdrawble)
        toast.success("Ставка успешна")
      })
      .on("error", err => {
        closeModal()
        toast.error(err.message)
      })
  } catch (err) {}
}

export {
  connectWallet,
  connectMetaMask,
  topUpCCO,
  withdrawCCO,
  getUsdtBalance,
  getCcoBalance,
  placeBet,
  getGameInfo,
  getTransactions,
  gameWithdraw,
  reInvest,
  getLastBet,
}
