import React from 'react';
import { compose } from 'redux';
import { withTagDefaultProps } from 'hoc';
import { TextLogo } from 'templates/Logo';
import { useSelector } from 'react-redux';
import translation from 'locales';

const AuthSidebar = ({ t }) => {
  const locale = useSelector((state) => state.web3.locale);
  const strings = translation[locale];

  return (
    <div className="auth__sidebar">
      <div
        className="auth__overlay"
        style={{ backgroundImage: 'url("/assets/images/content/card-bg.png")' }}
      />
      <TextLogo />
      <div className="slogan">
        <div>{t(strings['win.today1'])}</div>
        <div className="color_yellow">{t(strings['win.today2'])}</div>
      </div>
    </div>
  );
};

export default compose(withTagDefaultProps())(AuthSidebar);
