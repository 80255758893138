import React from "react"
import { compose } from "redux"
import PropTypes from "prop-types"
import { useLocation, useHistory } from "react-router-dom"
import { withTagDefaultProps } from "hoc"
import { withServiceConsumer } from "services/Context"
import { Button } from "templates/Button"
import { Img } from "templates/Img"
import { useState } from "react"
import { ReactModal } from "templates/ReactModal"
import { useSelector } from "react-redux"
import { getTransactions } from "services/Web3"
import translation from "locales"

const propTypes = {
  total: PropTypes.string.isRequired,
  onClickReplenish: PropTypes.func.isRequired,
  onClickWithdraw: PropTypes.func.isRequired,
}

const BalanceCard = ({ total, onClickReplenish, onClickWithdraw, t }) => {
  const [txnHistory, setTxnHistory] = useState([])
  const history = useHistory()
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const closeModal = () => {
    setOpen(false)
  }

  const openModal = () => {
    setOpen(true)
  }

  const getAdd = useSelector(state => state.web3.address)
  const locale = useSelector(state => state.web3.locale)

  const GetHistory = async () => {
    await getTransactions(getAdd, setTxnHistory)
  }

  const isSwapPage = location.pathname === "/swap"

  const strings = translation[locale]

  return (
    <div
      className="balance-card"
      style={{
        backgroundImage: 'url("/assets/images/content/balance-card-bg.svg")',
      }}>
      <div className="balance-card__title">{t(strings["balance"])}</div>
      <div className="balance-card__row">
        <div className="balance-card__col">
          <span className="balance-card__total">{total}</span>
          <span className="balance-card__icon">
            <Img
              src="/assets/images/icons/balance-icon.svg"
              alt="balance-icon"
            />
          </span>
          <span className="balance-card__name">PLAY Token</span>
        </div>
        {/* <div className="balance-card__col">
                    <button type="button" className="game__head-btn transaction__btn" onClick={() => { openModal(); GetHistory(); }}>
                        {t('История транзакций')}
                    </button>
                </div> */}
      </div>
      <div className="balance-card__row">
        {isSwapPage ? (
          <>
            <div className="balance-card__col">
              <Button name="replenish" onClick={onClickReplenish} fullWidth>
                {t(strings["topup.game"])}
              </Button>
            </div>
            <div className="balance-card__col">
              <Button
                name="withdraw"
                isBordered
                onClick={onClickWithdraw}
                fullWidth>
                {t(strings["withdraw.game"])}
              </Button>
            </div>
          </>
        ) : (
          <div className="balance-card__col">
            <Button
              name="replenish"
              onClick={() => {
                history.push("/swap")
              }}
              fullWidth>
              {t(strings["topup.swap"])}
            </Button>
          </div>
        )}
      </div>
      <ReactModal
        isOpen={open}
        onRequestClose={closeModal}
        title="Top up"
        body={txnHistory}>
        <div
          style={{ width: 360 }}
          className="balance-card"
          style={{
            backgroundImage:
              'url("/assets/images/content/balance-card-bg.svg")',
          }}>
          <div className="balance-card__title">
            {t(strings["transac.history"])}
          </div>
        </div>
      </ReactModal>
    </div>
  )
}

BalanceCard.propTypes = propTypes

export default compose(
  withServiceConsumer,
  withTagDefaultProps(null, null),
)(BalanceCard)
