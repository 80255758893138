import { useState, FormEventHandler, ChangeEvent } from "react"

import clsx from "clsx"

import { Firestore } from "services/Firebase/Firestore"
import { useSelector } from "react-redux"
import { Input } from "templates/Form"
import { Button } from "templates/Button"

const {
  VITE_DEFAULT_PROMOCODE = "0xb98087ee3099e3454efd789af862db00a40c538b",
} = process.env

export const Promocode = ({ onConnect }: any) => {
  const address = useSelector((state: any) => state.web3.address)
  const [referrer, setReferrer] = useState("")
  const [error, setError] = useState("")

  if (!address) {
    throw new Error("Address must be provided to see this page")
  }

  let mode: true | false | null = null

  const checkPromocode = async (promocode: string) => {
    const val = await Firestore.isPromocodeExist(referrer)
    if (!val) throw new Error("Такого кода не существует!")
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = async ev => {
    ev.stopPropagation()
    ev.preventDefault()
    const referrer = ev.currentTarget.referrer.value
    if (mode === null) return console.error("Mode not selected #%d", mode)

    try {
      if (mode) {
        await checkPromocode(referrer)
      }

      await Firestore.addPromocode(
        address,
        referrer,
        Firestore.currentUser?.promocode!,
      )
      await Firestore.updateUser(address, {
        promocodeDecision: mode,
        referrer: mode ? referrer : VITE_DEFAULT_PROMOCODE,
      })

      onConnect(2)
    } catch (error: any) {
      setError(error.message as string || "Произошла ошибка! :(")
      console.error("Error happened on Promocode submit: %o", error)
    }
  }

  return (
    <div className="max-w-[300px] promocode-wrapper md:max-w-[460px] mx-auto bg-white py-8 px-12 rounded-2xl">
      <h3 className="promocode-label">Введите код приглашения</h3>
      <form onSubmit={handleSubmit}>
        <Input
          type="text"
          className={clsx(
            "rounded-xl text-center bg-white w-full shadow-2xl mt-4 h-[56px] font-semibold text-[17px]",
            error && "border-red-500 border",
          )}
          autoFocus
          onChange={(value: any) => {
            setError("")
            setReferrer(value)
          }}
          value={referrer}
          name="referrer"
        />
        <div className="w-full">
          {error && <p className="font-medium text-red-600">{error}</p>}
        </div>
        <Button
          type="submit"
          disabled={Boolean(!referrer)}
          onClick={() => {
            mode = true
          }}
          className="w-full mt-4 font-semibold text-white bg-black rounded-full h-14">
          Далее
        </Button>
        {/* <Button
          type="submit"
          onClick={() => {
            mode = false
          }}
          className="mt-8 uppercase text-black/50">
          У меня нет кода
        </Button> */}
      </form>
    </div>
  )
}
