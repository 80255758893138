import getStoreItem, { getActionStore } from './StoreService';
import { requestInitPage, requestSubmitForm } from './RequestService';

const pref = 'cabinet';

export {
  getStoreItem,
  getActionStore,
  pref,
  requestInitPage,
  requestSubmitForm,
};
