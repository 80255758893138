import { combineReducers } from "redux";
import AppReducer from "./AppReducer";
import PageReducer from "./PageReducer";
import web3Reducer from "./Web3Reducer";
import { authReducer } from "./AuthReducer";

export default combineReducers({
    appState: AppReducer,
    pageState: PageReducer,
    web3: web3Reducer,
    auth: authReducer
});
